<template>
  <el-container class="shopList">
    <el-header class="box add">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>车辆管理</el-breadcrumb-item>
        <el-breadcrumb-item>车辆信息列表</el-breadcrumb-item>
        <el-breadcrumb-item v-if="form.id">编辑车辆</el-breadcrumb-item>
        <el-breadcrumb-item v-else>新增车辆</el-breadcrumb-item>
      </el-breadcrumb>
      <div>
        <el-button class="back_btn" size="medium" plain @click="$router.back()"
          >返回</el-button
        >
      </div>
    </el-header>
    <el-main class="p15 pt15">
      <el-form
        status-icon
        ref="form"
        :model="form"
        label-width="100px"
        size="medium"
        style="text-align: left"
      >
        <el-row class="box mt20 f14">
          <el-col :span="24">
            <el-form-item label="车辆性质" class="w">
              <el-radio-group v-model="form.nature">
                <el-radio-button label="自有"></el-radio-button>
                <el-radio-button label="租赁"></el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="车牌号" class="w">
              <el-input
                v-if="form.nature === '自有'"
                v-model="form.car_number"
                style="width: 100%"
              ></el-input>
              <el-autocomplete
                v-else
                v-model="form.car_number"
                value-key="car_number"
                :fetch-suggestions="querySearchAsync"
                @select="handleSelect"
                style="width: 100%"
                @change="handleCarNumberChange"
              ></el-autocomplete>
            </el-form-item>
          </el-col>
          <template v-if="form.nature === '自有'">
            <el-col :span="11">
              <el-form-item label="行驶证" class="w">
                <el-input
                  v-model="form.car_driving_license"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24" class="tl">
              <el-form-item size="medium" label="行驶证图片" class="w">
                <div>
                  <el-upload
                    ref="upload"
                    type="file"
                    action=""
                    :file-list="fileList"
                    list-type="picture-card"
                    :before-upload="beforeAvatarUpload"
                    accept="image/png,image/jpg,image/jpeg"
                    :http-request="
                      (params) => {
                        return handlepoll(params);
                      }
                    "
                    :on-success="handlepoll"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :class="{ disabled: uploadDisabled }"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-image-viewer
                    style="z-index: 9999"
                    v-if="dialogVisible"
                    :on-close="closeViewer"
                    :url-list="[dialogImageUrl]"
                  />
                </div>
              </el-form-item>
            </el-col>
          </template>
          <template v-else>
            <el-col :span="11">
              <el-form-item label="公司名称" class="w">
                <el-input
                  :disabled="form.company_id != ''"
                  v-model="form.company_name"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item label="统一社会信用代码" class="w">
                <el-input
                  :disabled="form.company_id != ''"
                  v-model="form.credit_code"
                  style="width: 100%"
                ></el-input>
              </el-form-item>
            </el-col>
          </template>
          <el-col :span="20" class="tc">
            <el-button
              class="ml_5"
              size="medium"
              type="primary"
              @click="submitForm('form')"
              >提交</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import axios from "@/util/api";
import { local, aa, objKeySort, session } from "@/util/util";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { tengxuncloud } from "@/util/aliOss";

export default {
  components: {
    ElImageViewer,
  },
  data() {
    return {
      loading: false,
      show: true,
      form: {
        id: "",
        nature: "自有",
        car_driving_license: "",
        car_driving_url: [],
        company_id: "",
        company_name: "",
        credit_code: "",
      },
      dialogVisible: false,
      dialogImageUrl: "",
      uploadDisabled: false,
      fileList: [],
    };
  },
  watch: {
    "form.car_driving_url"(val) {
      if (val.length > 5) {
        this.uploadDisabled = true;
      } else {
        this.uploadDisabled = false;
      }
    },
  },
  mounted() {
    var that = this;
    this.form.id = session.get("params").id
      ? String(session.get("params").id)
      : "";
    this.form.nature = session.get("params").name
      ? String(session.get("params").name)
      : "自有";
    this.company_id = local.get("company_id")
      ? String(local.get("company_id"))
      : "";
    this.getData({ id: this.form.id, nature: this.form.nature });
  },

  methods: {
    getData(params) {
      var that = this;
      if (this.form.id) {
        axios.get("/pc/company-car/single", params).then((v) => {
          if (params.nature == "自有") {
            that.$set(that.form, "car_number", v.data.car.car_number);
            that.form.car_driving_license = v.data.car.car_driving_license;
            if (v.data.car.car_driving_url) {
              let car_driving_url = v.data.car.car_driving_url;
              let path = car_driving_url
                .slice(1, car_driving_url.length - 1)
                .split(", ");
              that.form.car_driving_url = path;
              if (path.length > 0) {
                that.fileList = path.map((item) => {
                  return { url: item };
                });
              }
            }
          } else {
            that.$set(that.form, "car_number", v.data.rent_car.car_number);
            that.form.company_id = v.data.rent_car.company_id;
            that.form.company_name = v.data.rent_car.company_name;
            that.form.credit_code = v.data.rent_car.credit_code;
          }
          // that.fileList = [
          //   {
          //     url: imageUrl + that.user.accessory_list[0].accessory_url,
          //   },
          // ];
          // that.uploadDisabled = true;
        });
      }
    },
    querySearchAsync(queryString, cb) {
      if (queryString) {
        axios
          .get("/pc/company-car/findByCarNumber", { car_number: queryString })
          .then((response) => {
            let results = response.data.car_list;
            cb(results);
          });
      } else {
        cb([]);
      }
    },
    handleSelect(item) {
      this.form.company_id = item.company_id;
      this.form.company_name = item.company_name;
      this.form.credit_code = item.credit_code;
    },

    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.form.id) {
            axios.put("/pc/company-car/update", this.form).then((v) => {
              this.$router.back();
            });
          } else {
            axios.post("/pc/company-car/save", this.form).then((v) => {
              this.$router.back();
            });
          }
        }
      });
    },
    beforeAvatarUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt10M;
    },

    handlepoll(response, file, fileList) {
      tengxuncloud(response).then((res) => {
        this.form.car_driving_url.push(res.url);
      });
    },

    handleRemove(file, fileList) {
      this.form.car_driving_url = fileList.map((item) => item.url);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    closeViewer() {
      this.dialogVisible = false;
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}吗？`);
    },
    handleCarNumberChange(val) {
      this.form.company_id = "";
      this.form.company_name = "";
      this.form.credit_code = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .active {
    background: #2ec68a;
    border-color: #2ec68a;
    color: #ffffff;
  }
  .back_btn {
    background: rgba(247, 248, 249, 1);
    border: 1px solid rgba(217, 222, 225, 1);
  }
  .recordadd-title {
    height: 34px;
    line-height: 34px;
    border-left: 2px solid #788287;
  }
  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
  /deep/ .el-checkbox-button__inner {
    border-left: 1px solid #dcdfe6;
    border-radius: 4px !important;
  }
  /deep/ .el-table.no-header .el-table__header {
    display: none;
  }
  .people-message .el-form-item {
    margin-bottom: 0px;
  }
  .recordadd-upload {
    width: 160px;
    height: 160px;
    float: left;
    margin-right: 10px;
    .disabled {
      height: 100%;
    }
    /deep/ .el-upload {
      width: 160px;
      height: 160px;
      line-height: 160px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 160px;
      height: 160px;
    }
  }
  .health_url {
    /deep/ .el-upload--picture-card {
      width: 70px;
      height: 40px;
      background-color: transparent;
      border: none;
      .el-button--medium {
        position: absolute;
        left: 10px;
        margin-top: 4px;
      }
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 70px;
      height: 40px;
    }
  }
  /deep/ .el-col-7 .el-form-item__content {
    width: initial;
  }
  table .cell .is-error {
    padding-bottom: 20px;
  }
  .el-table th.is-leaf,
  .el-table td {
    border-bottom: none;
  }
  /deep/ .disabled .el-upload--picture-card {
    display: none !important;
  }
  /deep/ .el-table {
    .el-table__body,
    .el-table__header {
      width: 100% !important;
    }
  }
  .el-checkbox-button {
    margin-bottom: 10px;
  }
}
.form-block {
  border-top: 1px solid #eaeefb;
  height: 44px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  margin-top: -1px;
  color: #d3dce6;
  cursor: pointer;
  position: relative;
}
</style>
